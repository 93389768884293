nav {
  background-color: #0d1117;
  align-self: flex-start;
  color: #c9d1d9;
}

nav .name {
  font-size: 1.8rem;
  margin-left: 1rem;
}

nav a {
  font-size: 1.8rem;
}

nav .collapse {
  justify-content: right;
}