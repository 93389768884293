.about {
    text-align: center;
}

.about h2 {
    font-size: 45px;
    padding: 30px;
}

.about img {
    max-height: 300px;
    max-width: 300px;
}

.about p {
    font-size: 25px;
    min-width: 370px;
}