#footer {
    margin-top: 15px;
    margin-bottom: 15px;
    align-self: end;
}

#footer a {
    padding: 0;
}

svg {
    color: white;
}